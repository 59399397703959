import { Component, OnInit } from '@angular/core';
import {formatDate} from '@angular/common';

import { LawyerinfoService } from '../lawyerinfo.service';
import { AppComponent } from '../app.component';
import { NavigationEnd, ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-leftsidebar',
  templateUrl: './leftsidebar.component.html',
  styleUrls: ['./leftsidebar.component.css']
})
export class LeftsidebarComponent implements OnInit {

  currentDate: Date = new Date();

  errorMessage: string;
  is_loggedin: boolean = false;
  todayonlinevisitor:any = [];
  todaytotalvisitor:any = [];
  is_menushow = false;

  constructor(
    public rest:LawyerinfoService, 
    public app:AppComponent,
    private router: Router,
    private activatedRoute: ActivatedRoute,
) { }
  
ngAfterViewInit(){

  this.gettodayonlinevisitor();
    
  this.gettodaytotalvisitor();

}

  ngOnInit() {

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.is_menushow =
        this.activatedRoute.firstChild.snapshot.data.is_menushow !== false;
        if(this.app.loggedIn()){
          this.is_loggedin = true;
        } else {
          this.is_loggedin = false;
        }
      }
    });
   
   
  }

  gettodayonlinevisitor() {
    let postData = {
    }
    //console.log(postData);

    this.rest
        .gettodayonlinevisitor(postData)
        .subscribe(
          todayonlinevisitor => {
              this.todayonlinevisitor = todayonlinevisitor
            },
            error => {
                this.errorMessage = <any>error
            }
        );
  }

  gettodaytotalvisitor() {
    let postData = {
    }
    //console.log(postData);

    this.rest
        .gettodaytotalvisitor(postData)
        .subscribe(
          todaytotalvisitor => {
              this.todaytotalvisitor = todaytotalvisitor
            },
            error => {
                this.errorMessage = <any>error
            }
        );
  }

}
