import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from "@angular/common/http";
import { Observable, of } from "rxjs";
//import { tap,map } from "rxjs/operators";
import { map, catchError, tap } from "rxjs/operators";

//const endpoint = 'http://localhost:8888/bdlawservice_admin/public/api/';
//const endpoint = "https://admin.bdlawservice.com/public/api/";
//const endpointOther = "https://reactnative.bdlawservice.com/public/api/";
//siddiqueenterprise.com
const endpoint = "https://admin.siddiqueenterprise.com/public/api/";
const endpointOther = "https://reactnative.siddiqueenterprise.com/public/api/";

const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": "application/json",
  }),
};


@Injectable({
  providedIn: "root",
})
export class JwtService {
  constructor(private httpClient: HttpClient) {}
  private extractData(res: Response) {
    let body = res;
    return body || {};
  }

  login(username: string, password: string): Observable<any> {
    return this.httpClient
      .post<{ access_token: string }>(endpoint + "login", {
        username,
        password,
      })
      .pipe(
        tap((res) => {

         // console.log(res);
          localStorage.setItem("access_token", res.api_token);
          localStorage.setItem("loggedin_name", res.name);
          localStorage.setItem("loggedin_username", res.username);
          localStorage.setItem("loggedin_lawyercode", res.l_id);
          localStorage.setItem("loggedin_ad_user", res.ad_user);
          localStorage.setItem("loggedin_lawyerType", res.l_type);
          if(res.username=='sadmin')
          {
            localStorage.setItem("issa", btoa("sadminLoggedIn"));
          }
        })
      );
  }

  bdLawlogin(postData): Observable<any> {
    return this.httpClient
      .post<{ access_token: string }>(endpointOther + "appsAndWebLogin",  postData, httpOptions)
      .pipe(
        tap((res) => {

         console.log(res);
         localStorage.setItem("access_token", res.users);
         localStorage.setItem("log_info_id", res.logId);
         localStorage.setItem("loggedin_name", res.user_info.name);
          localStorage.setItem("loggedin_username", res.user_info.l_id);
          localStorage.setItem("loggedin_lawyercode", res.user_info.l_id);
          localStorage.setItem("loggedin_ad_user", res.user_info.l_id === 100? "1":"0" );
         localStorage.setItem("loggedin_lawyerType", res.l_type);

          if(res.user_info.l_id === 100)
          {
            localStorage.setItem("issa", btoa("sadminLoggedIn"));
          }
        })
      );
  }


  blrlogin(lawyerCode: string, mobile: string): Observable<any> {
    return this.httpClient
      .post<{ access_token: string }>(endpointOther + "appsAndWebLogin", {
        username:lawyerCode,
        password:mobile,
      })
      .pipe(
        tap((res) => {

         // console.log(res);
         localStorage.setItem("blr_message", res.message);
         localStorage.setItem("blr_code", res.code);
         localStorage.setItem("blr_is_access", res.is_access); 

         
        })
      );
  }

  bdjclogin(username: string, password: string): Observable<any> {
    return this.httpClient
      .post<{ access_token: string }>(endpointOther + "appsAndWebLogin", {
        username,
        password,
      })
      .pipe(
        tap((res) => {
         // console.log(res);
         
          localStorage.setItem("bdjc_message", res.message);
          localStorage.setItem("bdjc_code", res.code);
          localStorage.setItem("bdjc_is_access", res.is_access);        
        })
      );
  }



  logout() {
    localStorage.removeItem("access_token");
    localStorage.removeItem("loggedin_name");
    localStorage.removeItem("loggedin_username");
    localStorage.removeItem("loggedin_lawyercode");
    localStorage.removeItem("loggedin_ad_user");
    localStorage.removeItem("loggedin_lawyerType");
    localStorage.removeItem("issa");
   // localStorage.clear();
  }
}
